import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searching)?_c(VSkeletonLoader,{staticClass:"custom_skeleton-loader",attrs:{"max-width":"100%","type":("list-item-avatar-three-line@" + _vm.perPage),"dark":""}}):_c(VRow,{staticClass:"mb-10 pb-12",attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.lists),function(item,index){return _c(VCol,{key:index,staticClass:"userContainer",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex w-100"},[(_vm.hasSelect)?_c(VCheckbox,{staticClass:"pl-5 align-self-center",attrs:{"id":("checkbox-" + (item[_vm.selectIdKey])),"on-icon":"mdi-check-circle-outline","off-icon":"mdi-checkbox-blank-circle-outline","color":"primary","value":item[_vm.selectIdKey],"disabled":_vm.custom.disabledSelect,"multiple":_vm.custom.multiple || true,"dark":""},on:{"click":function($event){return _vm.selectItem(item[_vm.selectIdKey])}},model:{value:(_vm.view_model),callback:function ($$v) {_vm.view_model=$$v},expression:"view_model"}}):_vm._e(),_c('router-link',{staticClass:"text-decoration-none d-flex white--text w-100",attrs:{"to":{
            name: _vm.routeName.PROFILE,
            query: {
              id: item.id || item.user_id
            }
          },"target":_vm.custom.navigate || ''}},[_c('div',{staticClass:"py-5 px-md-10 px-4 align-self-center"},[_c(VAvatar,{attrs:{"size":_vm.$isMobile() ? '80' : '130'}},[_c('img',{attrs:{"src":_vm.getImage(item),"crossorigin":"anonymous"}})])],1),(!_vm.$isMobile())?_c(VRow,{staticClass:"py-5",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"mb-5 font-weight-bold md-font projectRolesText",attrs:{"cols":"12"}},[(_vm.custom.projectRolesCol)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.getProjectRoles(item.project_role_ids))+" ")]):_vm._e(),(_vm.custom.nameCol)?_vm._t("name-col",null,{"item":item}):_c('div',[_vm._v(" "+_vm._s(item[_vm.nameKey])+" ")])],2),(_vm.custom.hasCharacteristic)?_vm._l((_vm.getProfileItems(item)),function(profileItem,idx){return _c(VCol,{key:idx,staticClass:"pr-2",attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(profileItem.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(profileItem.value || '-')+" ")])])}):_vm._e()],2):_c(VRow,{staticClass:"py-5",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"align-self-center projectRolesText",attrs:{"cols":"12"}},[(_vm.custom.projectRolesCol)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.getProjectRoles(item.project_role_ids))+" ")]):_vm._e(),(_vm.custom.nameCol)?_vm._t("name-col",null,{"item":item}):_c('div',[_vm._v(" "+_vm._s(item[_vm.nameKey])+" ")])],2)],1),_c('div',{staticClass:"my-10 mx-5 align-self-center"},[_c(VIcon,{staticClass:"icon",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }