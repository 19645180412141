<template>
  <div>
    <v-skeleton-loader
      v-if="searching"
      class="custom_skeleton-loader"
      max-width="100%"
      :type="`list-item-avatar-three-line@${perPage}`"
      dark
    ></v-skeleton-loader>
    <v-row no-gutters justify="center" class="mb-10 pb-12" v-else>
      <v-col
        v-for="(item, index) in lists"
        :key="index"
        cols="12"
        md="12"
        class="userContainer"
      >
        <div class="d-flex w-100">
          <v-checkbox
            :id="`checkbox-${item[selectIdKey]}`"
            on-icon="mdi-check-circle-outline"
            off-icon="mdi-checkbox-blank-circle-outline"
            class="pl-5 align-self-center"
            color="primary"
            :value="item[selectIdKey]"
            v-model="view_model"
            :disabled="custom.disabledSelect"
            :multiple="custom.multiple || true"
            @click="selectItem(item[selectIdKey])"
            dark
            v-if="hasSelect"
          ></v-checkbox>
          <router-link
            class="text-decoration-none d-flex white--text w-100"
            :to="{
              name: routeName.PROFILE,
              query: {
                id: item.id || item.user_id
              }
            }"
            :target="custom.navigate || ''"
          >
            <div class="py-5 px-md-10 px-4 align-self-center">
              <v-avatar :size="$isMobile() ? '80' : '130'">
                <img :src="getImage(item)" crossorigin="anonymous" />
              </v-avatar>
            </div>
            <v-row no-gutters class="py-5" v-if="!$isMobile()">
              <v-col cols="12" class="mb-5 font-weight-bold md-font projectRolesText">
                <div class="grey--text " v-if="custom.projectRolesCol">
                  {{ getProjectRoles(item.project_role_ids) }}
                </div>
                <slot name="name-col" :item="item" v-if="custom.nameCol"></slot>
                <div v-else>
                  {{ item[nameKey] }}
                </div>
              </v-col>
              <template v-if="custom.hasCharacteristic">
                <v-col
                  class="pr-2"
                  cols="12"
                  sm="2"
                  v-for="(profileItem, idx) in getProfileItems(item)"
                  :key="idx"
                >
                  <div class="grey--text">
                    {{ profileItem.title }}
                  </div>
                  <div>
                    {{ profileItem.value || '-' }}
                  </div>
                </v-col>
              </template>
            </v-row>
            <v-row class="py-5" no-gutters v-else>
              <v-col cols="12" class="align-self-center projectRolesText">
                <div class="grey--text" v-if="custom.projectRolesCol">
                  {{ getProjectRoles(item.project_role_ids) }}
                </div>
                <slot name="name-col" :item="item" v-if="custom.nameCol"></slot>
                <div v-else>
                  {{ item[nameKey] }}
                </div>
              </v-col>
            </v-row>
            <div class="my-10 mx-5 align-self-center">
              <v-icon color="white" class="icon"> mdi-chevron-right </v-icon>
            </div>
          </router-link>
        </div>
        
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants';
import { mapGetters } from 'vuex';
import { ANY } from '@/constants/constants';
import constantsProjectRoles from '@/filters/constants-project-roles.filter';

export default {
  name: "UserContainerList",
  props: {
    lists: {
      type: Array,
      default: () => ([])
    },
    searching: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 3
    },
    photoKey: {
      type: String,
      default: ''
    },
    nameKey: {
      type: String,
      default: ''
    },
    custom: {
      type: Object,
      default: () => ({})
    },
    hasSelect: {
      type: Boolean,
      default: false
    },
    value: { type: ANY, default: () => '' },
    selectIdKey: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      routeName: ROUTE_NAME,
    }
  },
  computed: {
    ...mapGetters({
      languagesSpoken: ['constants/languageSpoken'],
      race: ['constants/race']
    }),
    view_model: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input');
      }
    },
  },
  methods: {
    selectItem(id) {
      const index = this.view_model.indexOf(id);
      if(index > -1) this.view_model.splice(index, 1)
      else this.view_model.push(id)
      this.$emit('select', this.view_model);
    },
    getProjectRoles(ids) {
      return ids.length ? constantsProjectRoles(ids, true).join(', ') : '';
    },
    getImage(item) {
      if(item[this.photoKey] || item.profile) return item[this.photoKey]?.media_path ||
        item?.profile[this.photoKey]?.media_path ||
        this?.$store?.getters['constants/avatarImageUrl']
      
      else return this?.$store?.getters['constants/avatarImageUrl']
    },
    getProfileItems(item) {
        let languages = '';

        if (item?.profile?.languages_spoken) {
          item.profile.languages_spoken.forEach((x, index, arr) => {
            const language = this.languagesSpoken.find((y) => y.value === x);
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item?.profile?.height
          },
          {
            title: 'Race',
            value: item?.profile?.race
              ? this.race.find((x) => x.value == item.profile.race).description
              : null
          },
          {
            title: 'Language',
            value: languages
          },
          {
            title: 'Skills',
            value: item?.profile?.skills
          }
        ];
      },
  }
}
</script>

<style scoped lang="scss">
.userContainer {
  background-color: #242424;
  border-radius: 5px;
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.projectRolesText > div{
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1263px) {
  .projectRolesText > div{
    width: 100%;
  }
}

::v-deep.custom_skeleton-loader > .v-skeleton-loader__bone {
  height: 180px;
  margin-bottom: 1rem;

}

</style>